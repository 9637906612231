import Link from 'next/link';
import React, { ReactNode } from 'react';
import { CtaButton } from '../common/CtaButton';
import { BarBox } from '../ui/Bar/BarBox';
import { BarButton } from '../ui/Bar/BarButton';
import { BarContent } from '../ui/Bar/BarContent';
import { BarDesc } from '../ui/Bar/BarDesc';
import { BarSection } from '../ui/Bar/BarSection';
import { BarTitle } from '../ui/Bar/BarTitle';
import { Box } from '../ui/Box/Box';
import { Button } from '../ui/Button/Button';
import { CtaBox } from '../ui/Cta/CtaBox';
import { CtaButtons } from '../ui/Cta/CtaButtons';
import { CtaDesc } from '../ui/Cta/CtaDesc';
import { CtaSection } from '../ui/Cta/CtaSection';
import { CtaTitle } from '../ui/Cta/CtaTitle';
import { MaxWidth } from '../ui/layout/MaxWidth';
import { RowMain } from '../ui/layout/RowMain';
import { Markdown } from './Markdown';
import { TPageBlock } from './pageBlockTypes';

type Props = Extract<TPageBlock, { __typename: 'ComponentPageSectionsCtaBox' }>;

export const PageSectionsCtaBox = ({ heading, content, ctaButton, ctaVariant }: Props) => {
	return ctaVariant === 'simple' ? (
		<MaxWidth variant="780" isCenter>
			<Box title={heading}>
				<Markdown source={content} />
			</Box>
		</MaxWidth>
	) : ctaVariant === 'banner' ? (
		<BarSection>
			<RowMain>
				<BarBox>
					<BarContent>
						<BarTitle>{heading}</BarTitle>
						<BarDesc>
							<Markdown source={content} />
						</BarDesc>
					</BarContent>
					<BarButton>
						{ctaButton?.map<ReactNode>(
							(button) => button && <CtaButton button={button} variant="secondary" />
						)}
					</BarButton>
				</BarBox>
			</RowMain>
		</BarSection>
	) : (
		<CtaSection>
			<RowMain>
				<CtaBox>
					<CtaTitle>{heading}</CtaTitle>
					<CtaDesc>
						<Markdown source={content} />
					</CtaDesc>
					{ctaButton && ctaButton[0] && <CtaButton button={ctaButton[0]} />}
				</CtaBox>
			</RowMain>
		</CtaSection>
	);
};
