import { useCallback, useState } from 'react';
import { Button } from '../ui/Button/Button';
import { PlayIcon } from '../ui/icons/PlayIcon';
import { Image } from '../ui/Image/Image';
import { VisuallyHidden } from '../ui/Utilities/VisuallyHidden';
import { VideoControls } from '../ui/Video/VideoControls';
import { PopupPortal } from './PopupPortal';
import { Popup } from '../ui/Popup/Popup';
import { getSrcSkyProxy } from '../ui/Utilities/getSrcSkyProxy';

type Props = {
	youtubeId: string;
	posterUrl?: string;
	srcSet?: number[];
	sizes?: string;
};

export const MediaBoxYoutube = ({ youtubeId, posterUrl, srcSet, sizes }: Props) => {
	const [activated, setActivated] = useState(false);
	const onClickPlay = useCallback(() => {
		setActivated(true);
	}, []);

	const onClickClose = useCallback(() => {
		setActivated(false);
	}, []);

	return (
		<>
			<PopupPortal>
				<Popup onClickClose={onClickClose} maximized hidden={!activated}>
					<div style={{ width: '90vw', height: '90vh', position: 'relative' }}>
						{activated && (
							<iframe
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
								src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1`}
								className="fullsize"
							/>
						)}
					</div>
				</Popup>
			</PopupPortal>
			{!activated && (
				<>
					{posterUrl ? (
						<Image
							src={posterUrl}
							srcSet={srcSet && getSrcSkyProxy(posterUrl, srcSet)}
							sizes={sizes}
							isCover
						/>
					) : (
						<Image src={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`} isCover />
					)}
					<VideoControls>
						<Button type="button" variant="play" icon={<PlayIcon />} onClick={onClickPlay}>
							<VisuallyHidden>play</VisuallyHidden>
						</Button>
					</VideoControls>
				</>
			)}
		</>
	);
};
