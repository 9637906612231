import React from 'react';
import { AboutContent } from '../ui/About/AboutContent';
import { AboutImage } from '../ui/About/AboutImage';
import { AboutSection } from '../ui/About/AboutSection';
import { AboutTitle } from '../ui/About/AboutTitle';
import { Image } from '../ui/Image/Image';
import { getSrcSkyProxy } from '../ui/Utilities/getSrcSkyProxy';
import { Markdown } from './Markdown';
import { TPageBlock } from './pageBlockTypes';

type Props = Extract<TPageBlock, { __typename: 'ComponentPageSectionsDoubleImageRow' }>;

export const PageSectionsDoubleImageRow = ({ leftMedia, rightMedia, heading, content }: Props) => {
	return (
		<AboutSection>
			{leftMedia?.url && (
				<AboutImage>
					<Image
						src={leftMedia.url}
						loading="lazy"
						srcSet={getSrcSkyProxy(leftMedia.url, [943, 598, 564])}
						sizes="(min-width: 1279px) 943px, (min-width: 999px) 598px, (min-width: 749px) calc(100vw - 2 * 28px), (min-width: 599px) 564px, calc(100vw - 2 * 18px)"
					/>
				</AboutImage>
			)}
			<AboutContent>
				<AboutTitle>{heading}</AboutTitle>
				<Markdown source={content} />
			</AboutContent>
			{rightMedia?.url && (
				<AboutImage variant="right">
					<Image
						src={rightMedia.url}
						srcSet={getSrcSkyProxy(rightMedia.url, [943, 598, 564])}
						sizes="(min-width: 1279px) 943px, (min-width: 999px) 598px, (min-width: 749px) calc(100vw - 2 * 28px), (min-width: 599px) 564px, calc(100vw - 2 * 18px)"
						loading="lazy"
					/>
				</AboutImage>
			)}
		</AboutSection>
	);
};
