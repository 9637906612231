import { useCallback, useRef, useState } from 'react';
import { Button } from '../ui/Button/Button';
import { PlayIcon } from '../ui/icons/PlayIcon';
import { VisuallyHidden } from '../ui/Utilities/VisuallyHidden';
import { Video } from '../ui/Video/Video';
import { VideoControls } from '../ui/Video/VideoControls';
import { PopupPortal } from './PopupPortal';
import { Popup } from '../ui/Popup/Popup';

type Props = {
	src: string;
	posterUrl?: string;
};

export const MediaBoxVideo = ({ src, posterUrl }: Props) => {
	const [activated, setActivated] = useState(false);
	const videoRef = useRef<HTMLVideoElement>(null);

	const onClickPlay = useCallback(() => {
		if (videoRef.current) {
			videoRef.current.play();
			setActivated(true);
		}
	}, []);

	const onClickClose = useCallback(() => {
		setActivated(false);
		if (videoRef.current) {
			videoRef.current.pause();
		}
	}, []);

	return (
		<>
			<PopupPortal>
				<Popup onClickClose={onClickClose} maximized hidden={!activated}>
					<video src={src} ref={videoRef} controls={activated} poster={posterUrl} />
				</Popup>
			</PopupPortal>
			<Video src={src} isCover controls={false} poster={posterUrl}></Video>
			{!activated && (
				<VideoControls>
					<Button type="button" variant="play" icon={<PlayIcon />} onClick={onClickPlay}>
						<VisuallyHidden>play</VisuallyHidden>
					</Button>
				</VideoControls>
			)}
		</>
	);
};
