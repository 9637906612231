export const getCookie = (cname: string) => {
	const name = cname + '=';
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
};

export const addHubspotParams = (data: any) => {
	if (document) {
		return {
			...data,
			hubspotParams: {
				pageName: document.title,
				pageUri: document.location.href,
				hutk: getCookie('hubspotutk'),
			},
		};
	} else {
		return data;
	}
};
export default addHubspotParams;

export const getHubspotParamsJson = () => {
	if (document) {
		return {
			pageName: document.title,
			pageUri: document.location.href,
			hutk: getCookie('hubspotutk'),
		};
	}
	return '';
};
