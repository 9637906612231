import classNames from 'classnames';
import { FC } from 'react';

type Props = {
	variant?: 'right';
};

export const AboutImage: FC<Props> = ({ children, variant }) => {
	const classes = classNames('s-about__image', variant && `s-about__image--${variant}`);
	return <div className={classes}>{children}</div>;
};
