import { FC, VideoHTMLAttributes, forwardRef } from 'react';
import classNames from 'classnames';

type Props = {
	isCover?: boolean;
} & VideoHTMLAttributes<HTMLVideoElement>;

export const Video = forwardRef<HTMLVideoElement, Props>(({ children, isCover, ...rest }, ref) => {
	const className = classNames('video', isCover ? 'is-cover' : false, isCover ? 'fullsize' : false);
	return (
		<video {...rest} className={className} ref={ref}>
			{children}
		</video>
	);
});
