import { IconWrapper } from './IconWrapper';
import { FC } from 'react';

type Props = {
	width?: number;
	height?: number;
};

export const SuccessIcon: FC<Props> = ({ width = 13, height = 12 }) => {
	return (
		<IconWrapper nativeWidth={13} nativeHeight={12} width={width} height={height}>
			<path d="M11.4,4c-0.3,0.1-0.4,0.4-0.4,0.7c0.1,0.4,0.2,0.9,0.2,1.3c0,2.7-2.2,4.9-4.9,4.9S1.3,8.7,1.3,6 s2.2-4.9,4.9-4.9c0.9,0,1.8,0.3,2.6,0.7C9.1,2,9.4,1.9,9.6,1.6C9.7,1.4,9.6,1,9.4,0.9C8.4,0.3,7.3,0,6.2,0c-3.3,0-6,2.7-6,6 s2.7,6,6,6s6-2.7,6-6c0-0.5-0.1-1.1-0.2-1.6C12,4.1,11.6,4,11.4,4z" />
			<path d="M12.6,0.7c-0.2-0.2-0.5-0.2-0.8,0L6.2,6.3L4.4,4.5c-0.2-0.2-0.5-0.2-0.8,0c-0.2,0.2-0.2,0.5,0,0.8 l2.2,2.2C6,7.6,6.1,7.6,6.2,7.6s0.3-0.1,0.4-0.2l6-6C12.8,1.3,12.8,0.9,12.6,0.7z" />
		</IconWrapper>
	);
};
