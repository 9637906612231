import { FC, ImgHTMLAttributes, forwardRef } from 'react';
import classNames from 'classnames';

type Props = {
	isCover?: boolean;
	objectFit?: 'contain' | 'cover';
} & ImgHTMLAttributes<HTMLImageElement>;

export const Image = forwardRef<HTMLImageElement, Props>(({ isCover, objectFit = 'cover', ...rest }: Props, ref) => {
	const className = classNames('image', objectFit === 'contain' ? 'contain' : 'is-cover', isCover && 'fullsize');
	return <img {...rest} className={className} ref={ref} />;
});
