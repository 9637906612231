import { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

type Props = { spacing?: 'sm' } & HTMLAttributes<HTMLDivElement>;

export const ReasonSection: FC<Props> = ({ children, spacing, ...props }) => {
	const className = classNames('s-reason', spacing && `s-reason--spacing-${spacing}`);
	return (
		<div className={className} {...props}>
			{children}
		</div>
	);
};
