import { HTMLProps, forwardRef, ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
	textareaLabel?: ReactNode;
	variant?: 'sm' | 'md';
} & HTMLProps<HTMLTextAreaElement>;

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(({ variant, textareaLabel, ...props }: Props, ref) => {
	const classes = classNames('inp-text', variant ? `inp-text--${variant}` : '');
	return (
		<span className="inp-fix">
			<textarea className={classes} {...props} ref={ref}></textarea>
			{textareaLabel && textareaLabel}
		</span>
	);
});
