import Link from 'next/link';
import React from 'react';
import { MediaSwitch } from '../common/MediaSwitch';
import { Button } from '../ui/Button/Button';
import { HalfBox } from '../ui/HalfBox/HalfBox';
import { HalfBoxContent } from '../ui/HalfBox/HalfBoxContent';
import { HalfBoxSection } from '../ui/HalfBox/HalfBoxSection';
import { HalfBoxSide } from '../ui/HalfBox/HalfBoxSide';
import { HalfBoxSideHolder } from '../ui/HalfBox/HalfBoxSideHolder';
import { RowMain } from '../ui/layout/RowMain';
import { BoxParams } from '../ui/Params/BoxParams';
import { BoxParamsContent } from '../ui/Params/BoxParamsContent';
import { BoxParamsImage } from '../ui/Params/BoxParamsImage';
import { BoxParamsTitle } from '../ui/Params/BoxParamsTitle';
import { SectionParams } from '../ui/Params/SectionParams';
import { TextBlock } from '../ui/TextBlock/TextBlock';
import { Markdown } from './Markdown';
import { TPageBlock } from './pageBlockTypes';
import { Image } from '../ui/Image/Image';
import { getSrcSkyProxy } from '../ui/Utilities/getSrcSkyProxy';
import { Table } from '../ui/Table/Table';

type Props = Extract<TPageBlock, { __typename: 'ComponentPageSectionsParametersTable' }>;

export const PageSectionsParametersTable = ({ heading, media, row: rows, tableVariant }: Props) => {
	return tableVariant === 'image' ? (
		<SectionParams>
			<RowMain>
				<BoxParamsTitle>{heading}</BoxParamsTitle>
				<BoxParams>
					<BoxParamsContent>
						<div className="b-table">
							<table>
								<tbody>
									{rows?.map((row) => (
										<tr key={row?.id}>
											<th>{row?.firstColumn}</th>
											<td className="u-text-right">{row?.secondColumn}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</BoxParamsContent>
					{media?.url && (
						<BoxParamsImage>
							<Image
								src={media.url}
								srcSet={getSrcSkyProxy(media.url, [1410, 1128, 705, 564])}
								sizes="(min-width: 1409px) 705px, (min-width: 749px) 50vw, (min-width: 599px) 564px, calc(100vw - 2 * 18px)"
							/>
						</BoxParamsImage>
					)}
				</BoxParams>
			</RowMain>
		</SectionParams>
	) : (
		<Table variant={tableVariant === 'border' ? 'overview' : undefined} title={heading}>
			<tbody>
				{rows?.map((row) => (
					<tr key={row?.id}>
						<th>{row?.firstColumn}</th>
						<td className={tableVariant !== 'border' ? 'u-text-right' : undefined}>{row?.secondColumn}</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
};
