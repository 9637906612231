import React from 'react';
import { CtaButton } from '../common/CtaButton';
import { ContactBox } from '../ui/Contact/ContactBox';
import { ContactContent } from '../ui/Contact/ContactContent';
import { ContactInner } from '../ui/Contact/ContactInner';
import { ContactMap } from '../ui/Contact/ContactMap';
import { Grid } from '../ui/layout/Grid';
import { GridItem } from '../ui/layout/GridItem';
import { RowMain } from '../ui/layout/RowMain';
import { Markdown } from './Markdown';
import { TPageBlock } from './pageBlockTypes';

type Props = Extract<TPageBlock, { __typename: 'ComponentPageSectionsContactInfo' }>;

export const PageSectionsContactInfo = ({
	description,
	address,
	contacts,
	mapLink,
	additionalInfo,
	sale,
	contactButton,
}: Props) => {
	return (
		<RowMain>
			<ContactBox>
				<ContactInner>
					<ContactMap>
						{mapLink && <iframe src={mapLink} width="970" height="620" aria-hidden="false"></iframe>}
					</ContactMap>
					<ContactContent>
						<Markdown source={description} />
						<Grid spacingY="0" spacingX="md" valign="bottom">
							<GridItem mdSize={6} size={12}>
								<Markdown source={address} />
							</GridItem>
							<GridItem mdSize={6} size={12}>
								<Markdown source={additionalInfo} />
							</GridItem>
						</Grid>
						<Markdown source={contacts} />
						<Markdown source={sale} />
						{contactButton && <CtaButton button={contactButton} />}
					</ContactContent>
				</ContactInner>
			</ContactBox>
		</RowMain>
	);
};
