import { IconWrapper } from './IconWrapper';
import { FC } from 'react';

type Props = {
	width?: number;
	height?: number;
};

export const ErrorBigIcon: FC<Props> = ({ width = 69, height = 69 }) => {
	return (
		<IconWrapper id="error-big" nativeWidth={width} nativeHeight={height} width={width} height={height}>
			<g fillRule="nonzero">
				<path d="M34.5 0C15.43 0 0 15.43 0 34.5S15.43 69 34.5 69c7.858 0 15.237-2.588 21.37-7.38.384-.287.48-.957.193-1.34-.288-.384-.959-.48-1.342-.193-5.846 4.6-12.842 6.996-20.221 6.996-17.92 0-32.583-14.662-32.583-32.583 0-17.92 14.662-32.583 32.583-32.583 17.92 0 32.583 14.662 32.583 32.583 0 8.05-2.97 15.813-8.337 21.754-.384.384-.288.959.096 1.342.383.383.958.287 1.341-.096C65.838 51.175 69 43.03 69 34.5 69 15.43 53.57 0 34.5 0z" />
				<path d="M46.383 22.617a.926.926 0 00-1.341 0L34.5 33.158 23.958 22.617a.926.926 0 00-1.341 0 .926.926 0 000 1.341L33.158 34.5 22.617 45.042a.926.926 0 000 1.341c.191.192.479.288.67.288.192 0 .48-.096.671-.288L34.5 35.842l10.542 10.541c.191.192.479.288.67.288.192 0 .48-.096.671-.288a.926.926 0 000-1.341L35.842 34.5l10.541-10.542a.926.926 0 000-1.341z" />
			</g>
		</IconWrapper>
	);
};
