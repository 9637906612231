import { FC } from 'react';
import { Message } from '../ui/Message/Message';

const errorMessages: { [key: string]: string } = {
	user_email_already_exists: 'This e-mail is already used. Please enter a new email address. ',
	default: 'Oops, something went wrong here. Please try to refresh.',
};

type Props = {
	formError?: string | null;
};

export const FormErrorMessage: FC<Props> = ({ formError }) => {
	return formError ? (
		<Message type="error">
			<p>{errorMessages[formError] ?? formError}</p>
		</Message>
	) : null;
};
