import { FC } from 'react';

type Props = {
	isBlock?: boolean;
};

export const VisuallyHidden: FC<Props> = ({ children, isBlock }) => {
	if (isBlock) <div className="u-vhide">{children}</div>;
	return <span className="u-vhide">{children}</span>;
};
