import React from 'react';
import { HeroBox } from '../ui/Hero/HeroBox';
import { HeroImage } from '../ui/Hero/HeroImage';
import { HeroSection } from '../ui/Hero/HeroSection';
import { HeroTitle } from '../ui/Hero/HeroTitle';
import { Image } from '../ui/Image/Image';
import { RowMain } from '../ui/layout/RowMain';
import { getSrcSkyProxy } from '../ui/Utilities/getSrcSkyProxy';
import { MarkdownHeading } from './MarkdownHeading';
import { TPageBlock } from './pageBlockTypes';

type Props = Extract<TPageBlock, { __typename: 'ComponentPageSectionsHeroBox' }>;

export const PageBlockHeroBox = ({ heading, image }: Props) => {
	return (
		<HeroSection>
			<RowMain>
				<HeroBox>
					<HeroTitle>
						<MarkdownHeading source={heading} />
					</HeroTitle>
				</HeroBox>
			</RowMain>
			{image?.url && (
				<HeroImage>
					<Image
						src={image.url}
						isCover
						loading="lazy"
						srcSet={getSrcSkyProxy(image.url, [1170, 943, 598, 564])}
						sizes="(min-width: 1279px) 1170px, (min-width: 999px) 598px, (min-width: 749px) calc(100vw - 2 * 28px), (min-width: 599px) 564px, calc(100vw - 2 * 18px)"
					/>
				</HeroImage>
			)}
		</HeroSection>
	);
};
