import { FC, ReactNode } from 'react';

type Props = {
	title?: ReactNode;
};

export const ContactBox: FC<Props> = ({ children, title }) => {
	return (
		<div className="b-contact">
			{title && <h2 className="h3 u-text-center">{title}</h2>}
			{children}
		</div>
	);
};
