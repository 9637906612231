import classNames from 'classnames';
import { FC } from 'react';

type Props = {
	variant?: 'has-filter';
};

export const ToolItem: FC<Props> = ({ children, variant }) => {
	const classes = classNames('b-tool', variant && `b-tool--${variant}`);
	return <div className={classes}>{children}</div>;
};
