import { FC } from 'react';
import { CloseIcon } from '../icons/CloseIcon';
import { TextButton } from '../Button/TextButton';
import classNames from 'classnames';

export type Props = {
	onClickClose?: () => void;
	hidden?: boolean;
	maximized?: boolean;
};

export const Popup: FC<Props> = ({ children, onClickClose, hidden, maximized }) => {
	const classname = classNames('popup', maximized ? 'popup--maximized' : '', hidden ? 'popup--hidden' : '');
	return (
		<div className={classname}>
			<div className="popup__wrapper">
				<div className="popup__content">
					{children}
					<div className="popup__close">
						<TextButton type="button" onClick={onClickClose}>
							<CloseIcon />
						</TextButton>
					</div>
				</div>
			</div>
		</div>
	);
};
