import { Image } from '../ui/Image/Image';
import { getSrcSkyProxy } from '../ui/Utilities/getSrcSkyProxy';

type Props = {
	src: string;
	srcSet?: number[];
	sizes?: string;
};

export const MediaBoxImage = ({ src, srcSet, sizes }: Props) => {
	const skyProxySrcset = srcSet && getSrcSkyProxy(src, srcSet);
	return <Image src={src} isCover key={src} srcSet={skyProxySrcset} sizes={sizes} />;
};
