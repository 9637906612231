import { FC } from 'react';
import classNames from 'classnames';

type Props = {
	variant?: 'btn' | 'password';
	state?: 'error' | 'ok';
	component?: React.ElementType;
};

export const InpGroup: FC<Props> = ({ children, variant, state, component = 'p' }) => {
	const classes = classNames('inp', variant ? `inp--${variant}` : false, state && `has-${state}`);
	const C = component;
	return <C className={classes}>{children}</C>;
};
