import { FC } from 'react';

type Props = {};

export const IntroHeading: FC<Props> = ({ children }) => {
	return (
		<h1 className="s-intro__heading h2 heading-separator">
			<span className="heading-separator__text">{children}</span>
		</h1>
	);
};
