import Link from 'next/link';
import React, { FC, useState } from 'react';
import { ComponentPageComponentsCtaButton, Maybe, Page } from '../../types/codegen';
import { useEventTracker } from '../analytics/useEventTracker';
import { Markdown } from '../pageBlocks/Markdown';
import { Button, ButtonProps } from '../ui/Button/Button';
import { PhoneIcon } from '../ui/icons/PhoneIcon';
import { Collapsible } from '../ui/Utilities/Collapsible';

type Props = {
	button: Pick<ComponentPageComponentsCtaButton, 'buttonText' | 'phone' | 'textAfterClick'> & {
		page: Maybe<{ __typename?: 'Page' } & Pick<Page, 'slug'>>;
	};
	variant?: ButtonProps['variant'];
};
export const CtaButton: FC<Props> = ({ button, variant }) => {
	const [showPhone, setShowPhone] = useState(false);
	const [showText, setShowText] = useState(false);
	const eventTracker = useEventTracker();

	const handleContactClick = () => {
		setShowText(true);
		eventTracker.trackData({ event: 'viewContact' });
	};

	return button.textAfterClick ? (
		<>
			{!showText && (
				<Button type="button" onClick={handleContactClick}>
					{button?.buttonText}
				</Button>
			)}

			<Collapsible isCollapsed={!showText}>
				<Markdown source={button.textAfterClick} />
			</Collapsible>
		</>
	) : button.phone ? (
		<Button
			type="button"
			icon={<PhoneIcon />}
			onClick={() => setShowPhone(true)}
			href={showPhone ? `tel:${button?.phone.replace(/\s/g, '')}` : undefined}
		>
			{showPhone ? button?.phone : button?.buttonText}
		</Button>
	) : button.page?.slug ? (
		<Link key={button.buttonText} href={button.page.slug} passHref>
			<Button type="button" variant={variant}>
				{button?.buttonText}
			</Button>
		</Link>
	) : null;
};
