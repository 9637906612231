import React from 'react';
import { HalfBoxTitle } from '../ui/HalfBox/HalfBoxTitle';
import { MarkdownHeading } from './MarkdownHeading';
import { TPageBlock } from './pageBlockTypes';

type Props = Extract<TPageBlock, { __typename: 'ComponentPageSectionsSplitBoxHeading' }>;

export const PageSectionsSplitBoxHeading = ({ heading }: Props) => {
	return heading ? (
		<HalfBoxTitle>
			<MarkdownHeading source={heading} />
		</HalfBoxTitle>
	) : null;
};
