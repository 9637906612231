import React from 'react';
import { PageBlockHeroBox } from './PageBlockHeroBox';
import { TPageBlock } from './pageBlockTypes';
import { PageSectionsClaim } from './PageSectionsClaim';
import { PageSectionsContactForm } from './PageSectionsContactForm';
import { PageSectionsContactInfo } from './PageSectionsContactInfo';
import { PageSectionsCtaBox } from './PageSectionsCtaBox';
import { PageSectionsDoubleImageRow } from './PageSectionsDoubleImageRow';
import { PageSectionsFeaturesRow } from './PageSectionsFeaturesRow';
import { PageSectionsParametersTable } from './PageSectionsParametersTable';
import { PageSectionsSplitBoxHeading } from './PageSectionsSplitBoxHeading';
import { PageSectionsSplitBoxImage } from './PageSectionsSplitBoxImage';

type Props = {
	block: TPageBlock;
};

// TODO: [INX-189] Optimize component loading using dynamic import
export const PageBlock = ({ block }: Props) => {
	switch (block?.__typename) {
		case 'ComponentPageSectionsHeroBox':
			return <PageBlockHeroBox {...block} />;
		case 'ComponentPageSectionsFeaturesRow':
			return <PageSectionsFeaturesRow {...block} />;
		case 'ComponentPageSectionsClaim':
			return <PageSectionsClaim {...block} />;
		case 'ComponentPageSectionsSplitBoxImage':
			return <PageSectionsSplitBoxImage {...block} />;
		case 'ComponentPageSectionsSplitBoxHeading':
			return <PageSectionsSplitBoxHeading {...block} />;
		case 'ComponentPageSectionsContactInfo':
			return <PageSectionsContactInfo {...block} />;
		case 'ComponentPageSectionsContactForm':
			return <PageSectionsContactForm {...block} />;
		case 'ComponentPageSectionsCtaBox':
			return <PageSectionsCtaBox {...block} />;
		case 'ComponentPageSectionsDoubleImageRow':
			return <PageSectionsDoubleImageRow {...block} />;
		case 'ComponentPageSectionsParametersTable':
			return <PageSectionsParametersTable {...block} />;
		default:
			return null;
	}
};
