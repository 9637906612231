import { FC, ReactNode } from 'react';

type Props = {
	title?: ReactNode;
};

export const Box: FC<Props> = ({ children, title }) => {
	return (
		<div className="b-std">
			{title && <h2 className="b-std__title">{title}</h2>}
			{children}
		</div>
	);
};
