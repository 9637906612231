import { FC } from 'react';
import classNames from 'classnames';

type Props = {
	variant?: '780' | '870' | '970';
	isCenter?: boolean;
};

export const MaxWidth: FC<Props> = ({ children, variant = '615', isCenter = true }) => {
	const className = classNames(variant ? `u-max-${variant}` : '', isCenter ? 'u-block-center' : '');
	return <div className={className}>{children}</div>;
};
