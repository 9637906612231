import { FC, useCallback, useState } from 'react';
import { Image } from '../ui/Image/Image';
import { Popup } from '../ui/Popup/Popup';
import { getSrcSkyProxy } from '../ui/Utilities/getSrcSkyProxy';
import { PopupPortal } from './PopupPortal';

type Props = {
	src: string;
	srcSet?: number[];
	sizes?: string;
};

export const PopupImage: FC<Props> = ({ children, src, srcSet, sizes }) => {
	const [activated, setActivated] = useState(false);
	const onClick = useCallback(() => {
		setActivated(true);
	}, []);

	const onClickClose = useCallback(() => {
		setActivated(false);
	}, []);

	return (
		<>
			<PopupPortal>
				<Popup onClickClose={onClickClose} maximized hidden={!activated}>
					<div style={{ width: '90vw', height: '90vh', position: 'relative' }}>
						{activated && (
							<Image
								src={src}
								srcSet={getSrcSkyProxy(src, [1610, 1128, 705, 564])}
								sizes="(min-width: 1409px) 1610px, (min-width: 749px) 50vw, (min-width: 599px) 564px, calc(100vw - 2 * 18px)"
								isCover
								objectFit="contain"
							/>
						)}
					</div>
				</Popup>
			</PopupPortal>
			<a onClick={onClick}>{children}</a>
		</>
	);
};
