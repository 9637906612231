import { IconWrapper } from './IconWrapper';
import { FC } from 'react';

type Props = {
	width?: number;
	height?: number;
};

export const PlayIcon: FC<Props> = ({ width = 30, height = 35 }) => {
	return (
		<IconWrapper id="play" nativeWidth={width} nativeHeight={height} width={width} height={height}>
			<path d="M30 17.5L0 35V0l30 17.5z" />
		</IconWrapper>
	);
};
