import { FC, HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

export const SectionParams: FC<Props> = ({ children, ...props }) => {
	return (
		<div className="s-params" {...props}>
			{children}
		</div>
	);
};
