import { ComponentPageSectionsSplitBoxImage, UploadFile } from '../../types/codegen';
import { MediaBoxVideo } from './MediaBoxVideo';
import { MediaBoxYoutube } from './MediaBoxYoutube';
import { Image } from '../ui/Image/Image';
import { getSrcSkyProxy } from '../ui/Utilities/getSrcSkyProxy';

const posterMimeTypes: (string | undefined)[] = [
	'image/jpeg',
	'image/jpg',
	'image/png',
	'image/gif',
	'image/webp',
	'image/svg+xml',
];

type Props = Partial<Pick<ComponentPageSectionsSplitBoxImage, 'youtubeId'>> & {
	video?: Pick<UploadFile, 'url' | 'mime'> | null;
	poster?: Pick<UploadFile, 'url' | 'mime'> | null;
	srcSet?: number[];
	sizes?: string;
	isCover?: boolean;
};

export const MediaSwitch = ({ video, youtubeId, poster, srcSet, sizes, isCover = true }: Props) => {
	const mimeType = youtubeId ? 'youtube' : video?.mime;
	const posterUrl = posterMimeTypes.includes(poster?.mime) ? poster?.url : undefined;

	switch (mimeType) {
		case 'youtube':
			if (!youtubeId) break;
			return <MediaBoxYoutube youtubeId={youtubeId} posterUrl={posterUrl} srcSet={srcSet} sizes={sizes} />;
		case 'video/mp4':
			if (!video?.url) break;
			return <MediaBoxVideo src={video.url} posterUrl={posterUrl} />;
		case 'image/jpeg':
		case 'image/jpg':
		case 'image/png':
		case 'image/gif':
		case 'image/webp':
			if (!video?.url) break;
			const skyProxySrcset = srcSet && getSrcSkyProxy(video.url, srcSet);
			return (
				<Image
					src={video.url}
					isCover={isCover}
					key={video.url}
					srcSet={skyProxySrcset}
					sizes={sizes}
					loading="lazy"
				/>
			);
		case 'image/svg+xml':
			if (!video?.url) break;
			return <Image src={video.url} isCover key={video.url} />;
	}
	return null;
};
