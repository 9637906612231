import { FC } from 'react';

type Props = {};

export const CtaBox: FC<Props> = ({ children }) => {
	return (
		<div className="b-cta last-mb-0">
			<div className="b-cta__inner">{children}</div>
		</div>
	);
};
