import classNames from 'classnames';
import { FC } from 'react';

type Props = {
	type?: 'sm' | 'dots';
	align?: 'center';
};

export const ReasonBox: FC<Props> = ({ children, type, align }) => {
	const classes = classNames('b-reason', type && `b-reason--${type}`, align && `u-text-${align}`);
	if (type == 'dots') {
		return (
			<div className={classes}>
				<div className="b-reason__inner">{children}</div>
			</div>
		);
	}
	return <div className={classes}>{children}</div>;
};
