import { FC, Children } from 'react';
import { Grid } from '../layout/Grid';
import { GridItem } from '../layout/GridItem';

type Props = {};

export const ToolList: FC<Props> = ({ children }) => {
	return (
		<div className="c-tool">
			<Grid align="center">
				{Children.map(children, child => (
					<GridItem mdSize={6} lgSize={4}>
						{child}
					</GridItem>
				))}
			</Grid>
		</div>
	);
};
