import { FC, HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLHeadingElement>;

export const HalfBoxTitle: FC<Props> = ({ children, ...props }) => {
	return (
		<h2 className="s-half-box__title" {...props}>
			{children}
		</h2>
	);
};
