import { IconWrapper } from './IconWrapper';
import { FC } from 'react';

type Props = {
	width?: number;
	height?: number;
};

export const CloseIcon: FC<Props> = ({ width = 14, height = 14 }) => {
	return (
		<IconWrapper id="close" nativeWidth={width} nativeHeight={height} width={width} height={height}>
			<path d="M14 1.4L12.6 0 7 5.6 1.4 0 0 1.4 5.6 7 0 12.6 1.4 14 7 8.4l5.6 5.6 1.4-1.4L8.4 7 14 1.4z" />
		</IconWrapper>
	);
};
