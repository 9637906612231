import { FC } from 'react';

type Props = {};

export const ContactMap: FC<Props> = ({ children }) => {
	return (
		<div className="b-contact__map">
			<div className="b-map">{children}</div>
		</div>
	);
};
