import { FC } from 'react';
import classNames from 'classnames';
import { RowMain } from '../layout/RowMain';

export type Props = {};

export const BoxTitle: FC<Props> = ({ children }) => {
	const classes = classNames('title u-text-center');
	return (
		<h2 className={classes}>
			<RowMain>{children}</RowMain>
		</h2>
	);
};
