import ReactMarkdown, { ReactMarkdownProps } from 'react-markdown';

type Props = {
	source?: string | null;
} & Omit<ReactMarkdownProps, 'source'>;

export const MarkdownHeading = ({ source, ...props }: Props) => {
	return source ? (
		<ReactMarkdown
			source={source}
			allowedTypes={['text', 'paragraph', 'strong', 'break']}
			renderers={{ paragraph: ({ children }) => children }}
			{...props}
		/>
	) : null;
};
