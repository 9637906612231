import { FC } from 'react';
import classNames from 'classnames';

type Props = {
	variant?: 'scroll' | 'nowrap' | 'space-between';
	align?: 'center' | 'right';
	valign?: 'middle' | 'bottom';
	spacingX?: '0' | 'sm' | 'md' | 'lg';
	spacingY?: '0' | 'sm' | 'md' | 'lg';
};

export const Grid: FC<Props> = ({ children, variant, align, valign, spacingX, spacingY }) => {
	const classes = classNames(
		'grid',
		variant ? `grid--${variant}` : false,
		spacingX ? `grid--x-${spacingX}` : false,
		spacingY ? `grid--y-${spacingY}` : false,
		align ? `grid--${align}` : false,
		valign ? `grid--${valign}` : false
	);

	return <div className={classes}>{children}</div>;
};
