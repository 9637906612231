import Link from 'next/link';
import React from 'react';
import { MediaBoxImage } from '../common/MediaBoxImage';
import { PopupImage } from '../common/PopupImage';
import { BoxTitle } from '../ui/BoxTitle/BoxTitle';
import { Button } from '../ui/Button/Button';
import { Image } from '../ui/Image/Image';
import { RowMain } from '../ui/layout/RowMain';
import { ReasonBox } from '../ui/Reasons/ReasonBox';
import { ReasonButton } from '../ui/Reasons/ReasonButton';
import { ReasonContent } from '../ui/Reasons/ReasonContent';
import { ReasonGridList } from '../ui/Reasons/ReasonGridList';
import { ReasonHeading } from '../ui/Reasons/ReasonHeading';
import { ReasonImage } from '../ui/Reasons/ReasonImage';
import { ReasonSection } from '../ui/Reasons/ReasonSection';
import { ToolBgImg } from '../ui/Tool/ToolBgImg';
import { ToolDesc } from '../ui/Tool/ToolDesc';
import { ToolHeading } from '../ui/Tool/ToolHeading';
import { ToolItem } from '../ui/Tool/ToolItem';
import { ToolList } from '../ui/Tool/ToolList';
import { ToolSection } from '../ui/Tool/ToolSection';
import { ToolTitle } from '../ui/Tool/ToolTitle';
import { getSrcSkyProxy } from '../ui/Utilities/getSrcSkyProxy';
import { Markdown } from './Markdown';
import { MarkdownHeading } from './MarkdownHeading';
import { TPageBlock } from './pageBlockTypes';

type Props = Extract<TPageBlock, { __typename: 'ComponentPageSectionsFeaturesRow' }>;

export const PageSectionsFeaturesRow = ({ heading, features, variant }: Props) => {
	return variant === 'brackets' ? (
		<ToolSection>
			<RowMain>
				<ToolHeading>{heading}</ToolHeading>
				{features && features.length > 0 && (
					<ToolList>
						{features.map((feature) => (
							<ToolItem key={feature?.id}>
								<ToolTitle>
									<MarkdownHeading source={feature?.heading} />
								</ToolTitle>
								<ToolDesc>
									<Markdown source={feature?.content} />
								</ToolDesc>
								{feature?.featuresButton?.page?.slug && (
									<Link href={feature.featuresButton.page.slug} passHref>
										<Button isFullwidth>{feature.featuresButton.buttonText}</Button>
									</Link>
								)}
								{feature?.media?.url && (
									<ToolBgImg>
										<MediaBoxImage src={feature.media.url} />
									</ToolBgImg>
								)}
							</ToolItem>
						))}
					</ToolList>
				)}
			</RowMain>
		</ToolSection>
	) : (
		<ReasonSection spacing={variant === 'dots' ? 'sm' : undefined}>
			{variant === 'circles' ? (
				<BoxTitle>{heading}</BoxTitle>
			) : (
				<ReasonHeading align={'center'}>{heading}</ReasonHeading>
			)}
			<RowMain>
				{features && features.length > 0 && (
					<ReasonBox
						type={
							variant === 'dots'
								? 'dots'
								: variant === 'circles' || variant === 'noborder'
								? 'sm'
								: undefined
						}
						align={variant === 'noborder' ? 'center' : undefined}
					>
						<ReasonGridList>
							{features.map((feature) => {
								const image = feature?.media?.url && (
									<ReasonImage>
										<Image
											src={feature.media.url}
											srcSet={getSrcSkyProxy(feature.media.url, [564, 353, 326])}
											sizes="(min-width: 1199px) 353px, (min-width: 749px) 326px, (min-width: 599px) 564px, calc(100vw - 2 * 18px)"
											loading="lazy"
											isCover={variant !== 'dots'}
											objectFit="contain"
										/>
									</ReasonImage>
								);
								return (
									<ReasonContent key={feature?.id}>
										{feature?.media?.url && variant === 'noborder' ? (
											<PopupImage src={feature.media.url} key={feature.media.url}>
												{image}
											</PopupImage>
										) : (
											image
										)}
										{feature?.heading && (
											<h3>
												<MarkdownHeading source={feature.heading} />
											</h3>
										)}
										<Markdown source={feature?.content} />
									</ReasonContent>
								);
							})}
						</ReasonGridList>
						{features.map(
							(feature) =>
								feature?.featuresButton?.page?.slug && (
									<ReasonButton key={feature.featuresButton.page.slug}>
										<Link href={feature.featuresButton.page.slug} passHref>
											<Button>{feature.featuresButton.buttonText}</Button>
										</Link>
									</ReasonButton>
								)
						)}
					</ReasonBox>
				)}
			</RowMain>
		</ReasonSection>
	);
};
