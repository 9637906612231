import { GraphQLError } from 'graphql';
import { GetStaticPaths, GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { slugify } from '../components/navigation/slugify';
import { WebsiteLayout } from '../components/navigation/WebsiteLayout';
import { PageBlock } from '../components/pageBlocks/PageBlock';
import { TPageBlock } from '../components/pageBlocks/pageBlockTypes';
import { Main } from '../components/ui/layout/Main';
import { pageQuery } from '../gql/pageQuery';
import { pagesQuery } from '../gql/pagesQuery';
import createApolloClient from '../lib/apolloClient';
import { generateLangUrl, LOCALE_WITHOUT_SUFFIX, localize } from '../lib/localize';
import { GetAllPagesQuery, GetPageQuery, Maybe } from '../types/codegen';

type Props = {
	data: Omit<GetPageQuery, 'sk' | 'hu'>;
	errors: readonly GraphQLError[] | undefined;
	localizedSlugs:
		| {
				locale: string;
				slug: Maybe<string>;
		  }[]
		| null;
};

const GenericPage = ({ data, localizedSlugs }: Props) => {
	const page = data?.pages?.[0];
	const router = useRouter();
	const isHP = router.pathname === '/' || router.pathname === '/index';

	return (
		<WebsiteLayout
			menu={data?.mainMenu}
			isLogoClickable={!isHP}
			submenu={createSubmenuFromPageSections(page?.content)}
			localizedSlugs={localizedSlugs}
		>
			{page && (
				<Main variants={isHP ? 'hp' : undefined}>
					<NextSeo
						title={page.metaTags?.title ?? page.title ?? undefined}
						titleTemplate={page.slug ? undefined : '%s'}
						description={page.metaTags?.description ?? undefined}
						openGraph={{ images: page.metaTags?.ogImage ? [page.metaTags.ogImage] : undefined }}
						additionalMetaTags={
							page.metaTags?.ogImage
								? [
										{
											property: 'twitter:image',
											content: page.metaTags.ogImage.url,
										},
								  ]
								: undefined
						}
						languageAlternates={
							localizedSlugs
								? router.locales
										?.map((lang) => {
											return {
												hrefLang: lang,
												href: generateLangUrl(lang, localizedSlugs),
											};
										})
										.concat([
											{
												hrefLang: 'x-default',
												href: generateLangUrl('sk', localizedSlugs),
											},
										])
								: []
						}
					/>
					{page.content?.map((block, index) => (
						<Fragment key={index}>
							{block && 'sectionProps' in block && block.sectionProps?.showInMenu && (
								<a
									id={slugify(block.sectionProps.title || '')}
									style={{ position: 'relative', top: -145 }}
								/>
							)}
							<PageBlock block={block} key={index} />
						</Fragment>
					))}
				</Main>
			)}
		</WebsiteLayout>
	);
};

export default GenericPage;

export const getStaticProps: GetStaticProps = async ({ params, locale }) => {
	const pageslug = params?.page || '';
	const apolloClient = createApolloClient({}, true);

	try {
		const { data, errors } = await apolloClient.query<GetPageQuery>({
			query: pageQuery,
			variables: { slug: pageslug },
			fetchPolicy: 'no-cache', // workaround for circular references in JSON
		});
		const localizedPages = locale === 'sk' || locale === 'hu' ? data[locale] : data.pages;
		const firstPage = localizedPages?.length ? localizedPages?.[0] : null;
		const localizedSlugs =
			firstPage &&
			(Object.keys(firstPage).filter((key) => ['slug', 'slug_sk', 'slug_hu'].includes(key)) as Array<
				'slug' | 'slug_sk' | 'slug_hu'
			>).map((slugField) => {
				return {
					locale: slugField === 'slug' ? LOCALE_WITHOUT_SUFFIX : slugField.slice(-2),
					slug: firstPage[slugField],
				};
			});

		const localizedData: Omit<GetPageQuery, 'sk' | 'hu'> = {
			pages: [localize(firstPage, locale)],
			mainMenu: localize(data.mainMenu, locale),
		};

		return {
			props: {
				data: localizedData,
				localizedSlugs,
				errors: errors || null,
			},
		};
	} catch (e) {
		if (process.env.NODE_ENV !== 'production') {
			console.log('Error getStaticProps in /[page].tsx');
			console.dir(e, { depth: 10 });
		}
		throw e;
	}
};

export const getStaticPaths: GetStaticPaths = async () => {
	const apolloClient = createApolloClient({}, true);
	try {
		const { data, errors } = await apolloClient.query<GetAllPagesQuery>({ query: pagesQuery });
		if (errors) {
			console.dir(errors);
		}
		return {
			paths:
				data.pages?.flatMap((page) =>
					page
						? [
								{
									params: {
										page: page.slug as string,
									},
									locale: 'cs',
								},
								{
									params: {
										page: page.slug_sk as string,
									},
									locale: 'sk',
								},
								{
									params: {
										page: page.slug_hu as string,
									},
									locale: 'hu',
								},
						  ].filter((page) => page.params.page)
						: []
				) || [],
			fallback: false,
		};
	} catch (e) {
		if (process.env.NODE_ENV !== 'production') {
			console.log('Error getStaticPaths in [page].tsx');
			console.dir(e, { depth: 10 });
		}
		throw e;
	}
};

const createSubmenuFromPageSections = (content: TPageBlock[] | null | undefined) => {
	return content
		?.map((item) =>
			item != null && 'sectionProps' in item && item.sectionProps?.showInMenu
				? item.sectionProps?.title || ''
				: null
		)
		?.filter(Boolean) as string[];
};
