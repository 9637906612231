import { FC, SyntheticEvent, forwardRef, RefObject, ReactNode, AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';

type Props = {
	onClick?: (e: SyntheticEvent) => void;
	href?: string;
	type?: 'submit' | 'button' | 'reset';
	disabled?: boolean;
	variant?: 'error' | 'warning' | 'success' | 'gray' | 'black';
	isBold?: boolean;
	isUnderline?: boolean;
	isBordered?: boolean;
	children: ReactNode;
	targetBlank?: boolean;
};

export const TextButton = forwardRef<HTMLAnchorElement, Props>(
	(
		{
			children,
			onClick,
			type,
			href,
			disabled,
			variant,
			isBold,
			isUnderline = true,
			isBordered,
			targetBlank = false,
		},
		ref
	) => {
		const props = {
			onClick,
			className: classNames(
				'as-link',
				disabled ? 'is-disabled' : false,
				variant ? `u-color-${variant}` : '',
				isBold ? 'text-bold' : '',
				!isUnderline ? 'u-text-no-underline' : '',
				isBordered ? 'link-btn' : ''
			),
			target: targetBlank ? 'target=_blank' : '',
		};

		if (href)
			return (
				<a href={href} ref={ref} {...props}>
					{children}
				</a>
			);

		if (!type) return <span {...props}>{children}</span>;

		return (
			<button type={type} disabled={disabled} {...props}>
				{children}
			</button>
		);
	}
);
