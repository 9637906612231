import { FC, ReactNode } from 'react';
import classNames from 'classnames';

export type Props = {
	variant?: 'overview';
	paging?: ReactNode;
	title?: ReactNode;
	boxId?: string;
};

export const Table: FC<Props> = ({ children, variant, title, paging, boxId }) => {
	const classes = classNames('b-table', variant ? `b-table--${variant}` : '');
	return (
		<div className={classes} id={boxId}>
			{title && <h2 className="b-table__title">{title}</h2>}
			<div className="b-table__wrapper">
				<table>{children}</table>
			</div>
			{paging && <div className="b-table__paging">{paging}</div>}
		</div>
	);
};
