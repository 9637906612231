import { IconWrapper } from './IconWrapper';
import { FC } from 'react';

type Props = {
	width?: number;
	height?: number;
};

export const PendingIcon: FC<Props> = ({ width = 13, height = 12 }) => {
	return (
		<IconWrapper nativeWidth={width} nativeHeight={height} width={width} height={height}>
			<path d="M6,0C2.7,0,0,2.7,0,6s2.7,6,6,6c3.3,0,6-2.7,6-6S9.3,0,6,0z M6,10.8c-2.7,0-4.8-2.1-4.8-4.8 S3.3,1.2,6,1.2s4.8,2.1,4.8,4.8S8.7,10.8,6,10.8z M6.3,3H5.4v3.6l3.2,1.9L9,7.8L6.3,6.1V3z" />
		</IconWrapper>
	);
};
