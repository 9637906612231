import { IconWrapper } from './IconWrapper';
import { FC } from 'react';

type Props = {
	width?: number;
	height?: number;
};

export const PhoneIcon: FC<Props> = ({ width = 26, height = 26 }) => {
	return (
		<IconWrapper id="phone" nativeWidth={width} nativeHeight={height} width={width} height={height}>
			<path d="M22.6 26h-.3c-3.9-.4-7.6-1.7-10.9-3.9-3-1.9-5.6-4.5-7.5-7.5C1.8 11.4.4 7.6 0 3.8c0-.5 0-1 .2-1.4.2-.5.4-.9.7-1.2.3-.4.7-.7 1.1-.9C2.5.1 3 0 3.4 0H7c.9 0 1.6.3 2.3.8.6.5 1 1.3 1.2 2.1.1 1.1.4 2.1.8 3.2.2.6.3 1.3.1 1.9-.1.6-.5 1.2-.9 1.7l-1 1C11 13 13 15 15.3 16.4l1-1c.5-.5 1.1-.8 1.7-.9.6-.1 1.3-.1 1.9.1 1 .4 2.1.6 3.2.8.8.1 1.6.5 2.2 1.2.5.6.8 1.5.8 2.3v3.6c0 .5-.1.9-.3 1.4-.2.4-.5.8-.8 1.2-.4.3-.8.6-1.2.7-.5.1-.9.2-1.2.2zM7.1 2.1H3.4c-.2 0-.4 0-.6.1-.1.1-.2.2-.4.3-.1.1-.2.3-.3.5v.6c.4 3.5 1.6 7 3.5 9.9 1.8 2.8 4.1 5.1 6.9 6.9 3 1.9 6.4 3.2 9.9 3.5.2 0 .4 0 .5-.1.2-.1.3-.2.5-.3.1-.1.3-.3.3-.5.1-.2.1-.4.1-.6V19c0-.3-.1-.7-.3-.9-.2-.3-.5-.4-.9-.5-1.2-.2-2.4-.5-3.6-.9-.2-.1-.5-.1-.8-.1-.3.1-.5.2-.7.4L16 18.5c-.3.3-.8.4-1.2.2-3.2-1.8-5.8-4.4-7.6-7.6-.2-.4-.2-.9.2-1.2L9 8.3c.2-.2.3-.4.4-.7.1-.3 0-.5-.1-.8-.4-1.1-.7-2.3-.9-3.6 0-.3-.2-.6-.5-.8-.2-.2-.5-.3-.8-.3z" />
		</IconWrapper>
	);
};
