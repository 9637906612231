import { InputHTMLAttributes, forwardRef, ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
	label?: ReactNode;
	iconAfter?: boolean;
	icon?: ReactNode;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, Props>(
	({ children, icon, iconAfter, label, ...props }: Props, ref) => {
		const classes = classNames('inp-fix', icon ? 'inp-icon' : '', iconAfter ? 'inp-icon inp-icon--after' : false);
		return (
			<span className={classes}>
				<input {...props} className="inp-text" ref={ref} />
				{label}
				{icon && <span className="inp-icon__icon">{icon}</span>}
			</span>
		);
	}
);
