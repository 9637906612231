import React from 'react';
import { IntroHeading } from '../ui/Intro/IntroHeading';
import { IntroSection } from '../ui/Intro/IntroSection';
import { MarkdownHeading } from './MarkdownHeading';
import { TPageBlock } from './pageBlockTypes';

type Props = Extract<TPageBlock, { __typename: 'ComponentPageSectionsClaim' }>;

export const PageSectionsClaim = ({ heading }: Props) => {
	return (
		<IntroSection>
			{heading && (
				<IntroHeading>
					<MarkdownHeading source={heading} />
				</IntroHeading>
			)}
		</IntroSection>
	);
};
