import { FC, memo } from 'react';
import classNames from 'classnames';

type Props = {
	size?: number | 'auto' | 'side' | 'content' | 'autogrow';
	smSize?: number | 'auto';
	mdSize?: number | 'auto';
	lgSize?: number | 'auto';
	xlSize?: number | 'auto';
	align?: 'top' | 'middle' | 'bottom';
	hidden?: boolean;
};

export const GridItem: FC<Props> = memo(({ children, align, size, smSize, mdSize, lgSize, xlSize, hidden = false }) => {
	const className = classNames(
		'grid__cell',
		size
			? `size--${size}${
					size !== 'auto' && size !== 'side' && size !== 'content' && size !== 'autogrow' ? '-12' : ''
			  }`
			: false,
		smSize ? `size--${smSize}${smSize !== 'auto' ? '-12' : ''}@sm` : false,
		mdSize ? `size--${mdSize}${mdSize !== 'auto' ? '-12' : ''}@md` : false,
		lgSize ? `size--${lgSize}${lgSize !== 'auto' ? '-12' : ''}@lg` : false,
		xlSize ? `size--${xlSize}${xlSize !== 'auto' ? '-12' : ''}@xl` : false,
		align ? `grid__cell--${align}` : false,
		hidden && 'u-vhide'
	);

	return <div className={className}>{children}</div>;
});
