import { FC, Children } from 'react';
import { Grid } from '../layout/Grid';
import { GridItem } from '../layout/GridItem';

type Props = {};

export const ReasonGridList: FC<Props> = ({ children }) => {
	return (
		<Grid align="center" spacingX="md" spacingY="md">
			{Children.map(children, (child) => (
				<GridItem mdSize={4}>{child}</GridItem>
			))}
		</Grid>
	);
};
