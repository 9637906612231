import { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

type Props = {
	isReverse?: boolean;
	variant?: 'annot' | null;
} & HTMLAttributes<HTMLDivElement>;

export const HalfBox: FC<Props> = ({ children, variant, isReverse, ...props }) => {
	const className = classNames('b-half-box', isReverse && 'b-half-box--reverse', variant && `b-half-box--${variant}`);
	return (
		<div className={className} {...props}>
			{children}
		</div>
	);
};
