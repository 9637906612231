import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { PendingIcon } from '../icons/PendingIcon';
import { Iconned } from '../icons/Iconned';
import { SuccessIcon } from '../icons/SuccessIcon';
import { CrossIcon } from '../icons/CrossIcon';
import { SuccessBigIcon } from '../icons/SuccessBigIcon';
import { ErrorBigIcon } from '../icons/ErrorBigIcon';

export type Props = {
	type: 'warning' | 'success' | 'error';
	noIcon?: boolean;
	variant?: 'bordered' | 'plain' | 'big';
	isBlock?: boolean;
};

export const Message: FC<Props> = ({ children, type, variant = 'bordered', isBlock = true, noIcon }) => {
	const className = classNames('message', type && `u-color-${type}`, variant && `message--${variant}`);

	const icons = {
		success: variant === 'big' ? <SuccessBigIcon /> : <SuccessIcon />,
		warning: <PendingIcon />,
		error: variant === 'big' ? <ErrorBigIcon /> : <CrossIcon />,
	};

	const As = isBlock ? 'div' : 'span';

	return (
		<As className={className}>
			{noIcon ? (
				children
			) : (
				<Iconned icon={icons[type]} position="before" isBlock={isBlock}>
					{children}
				</Iconned>
			)}
		</As>
	);
};
