import { IconWrapper } from './IconWrapper';
import { FC } from 'react';

type Props = {
	width?: number;
	height?: number;
};

export const SuccessBigIcon: FC<Props> = ({ width = 69, height = 69 }) => {
	return (
		<IconWrapper id="success-big" nativeWidth={width} nativeHeight={height} width={width} height={height}>
			<g fillRule="nonzero">
				<path d="M34.5 0C15.43 0 0 15.43 0 34.5S15.43 69 34.5 69c9.775 0 19.167-4.217 25.683-11.5.384-.383.288-.958-.096-1.342-.383-.383-.958-.287-1.341.096-6.134 6.9-14.95 10.83-24.246 10.83-17.92 0-32.583-14.663-32.583-32.584 0-17.92 14.662-32.583 32.583-32.583 17.92 0 32.583 14.662 32.583 32.583a32.54 32.54 0 01-4.791 17.058c-.288.48-.096 1.054.287 1.342.48.288 1.054.096 1.342-.288C67.275 47.15 69 40.921 69 34.5 69 15.43 53.57 0 34.5 0z" />
				<path d="M49.833 22.617L28.08 44.37l-8.912-8.05c-.384-.383-.959-.288-1.342.096-.383.383-.287.958.096 1.341l10.254 9.2 23-23a.926.926 0 000-1.341.926.926 0 00-1.342 0z" />
			</g>
		</IconWrapper>
	);
};
