import { FC } from 'react';
import classNames from 'classnames';

type Props = {
	withOverlay?: boolean;
	alignY?: 'top' | 'middle' | 'bottom';
	alignX?: 'left' | 'center' | 'right';
};

export const VideoControls: FC<Props> = ({ children, withOverlay, alignY = 'middle', alignX = 'center' }) => {
	const className = classNames(
		'video-controls',
		'fullsize',
		withOverlay && 'has-overlay',
		alignY && `video-controls--${alignY}`,
		alignX && `video-controls--${alignX}`
	);
	return <p className={className}>{children}</p>;
};
