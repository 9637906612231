import { default as React, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEventTracker } from '../analytics/useEventTracker';
import { addHubspotParams } from '../common/addHubspotParams';
import { FormErrorMessage } from '../common/FormErrorMessage';
import { handleFetchError } from '../common/handleFetchError';
import { Button } from '../ui/Button/Button';
import { ContactBox } from '../ui/Contact/ContactBox';
import { ContactForm } from '../ui/Contact/ContactForm';
import { InpGroup } from '../ui/Form/InpGroup';
import { Input } from '../ui/Form/Input';
import { Label } from '../ui/Form/Label';
import { Textarea } from '../ui/Form/Textarea';
import { Grid } from '../ui/layout/Grid';
import { GridItem } from '../ui/layout/GridItem';
import { MaxWidth } from '../ui/layout/MaxWidth';
import { RowMain } from '../ui/layout/RowMain';
import { Message } from '../ui/Message/Message';
import { Markdown } from './Markdown';
import { TPageBlock } from './pageBlockTypes';

const EMAIL_VALIDATION = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

type Props = Extract<TPageBlock, { __typename: 'ComponentPageSectionsContactForm' }>;
type ContactFormData = {
	name: string;
	email: string;
	text: string;
};

export const PageSectionsContactForm = ({
	heading,
	nameLabel,
	messageLabel,
	buttonLabel,
	sentText,
	errorText,
	invalidEmail,
	invalidText,
}: Props) => {
	const [formError, setFormError] = useState<string | null>(null);
	const [showInvalidMsgs, setshowInvalidMsgs] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const eventTracker = useEventTracker();

	const { register, handleSubmit, errors, formState } = useForm<ContactFormData>({
		defaultValues: { name: '', email: '', text: '' },
		mode: 'onTouched',
	});

	const onSubmit = useCallback(
		async (data: ContactFormData) => {
			try {
				setshowInvalidMsgs(false);
				setFormError(null);
				await fetchContactForm(data);
				// await new Promise((resolve) => setTimeout(resolve, 3000));
				// console.log(data);
				eventTracker.trackData({ event: 'formSend' });
				setSubmitted(true);
			} catch (e) {
				setFormError(errorText || e.message);
			}
		},
		[errorText, eventTracker]
	);

	const onError = useCallback(() => setshowInvalidMsgs(true), []);

	return (
		<ContactBox title={heading}>
			<RowMain>
				<MaxWidth isCenter variant="970">
					{submitted ? (
						<Message type="success" variant="big">
							<Markdown source={sentText} />
						</Message>
					) : (
						<ContactForm>
							<form onSubmit={handleSubmit(onSubmit, onError)}>
								<FormErrorMessage formError={formError} />
								<Grid spacingX="sm" spacingY="0" align="center">
									<GridItem mdSize={6}>
										<InpGroup state={!!errors.name?.type ? 'error' : undefined}>
											<Input
												type="text"
												name="name"
												id="name"
												placeholder=" "
												label={<Label htmlFor="name">{nameLabel}</Label>}
												ref={register}
											/>
										</InpGroup>
									</GridItem>
									<GridItem mdSize={6}>
										<InpGroup state={!!errors.email?.type ? 'error' : undefined}>
											<Input
												type="email"
												name="email"
												id="email"
												placeholder=" "
												label={<Label htmlFor="email">E-mail</Label>}
												ref={register({ required: true, pattern: EMAIL_VALIDATION })}
											/>
										</InpGroup>
										{showInvalidMsgs && errors.email?.type && invalidEmail && (
											<p className="u-color-error">{invalidEmail}</p>
										)}
									</GridItem>
									<GridItem mdSize={12}>
										<InpGroup state={!!errors.text?.type ? 'error' : undefined}>
											<Textarea
												variant="md"
												cols={40}
												rows={5}
												placeholder=" "
												name="text"
												id="text"
												ref={register({ required: true })}
												textareaLabel={<Label htmlFor="text">{messageLabel}</Label>}
											/>
										</InpGroup>
										{showInvalidMsgs && errors.text?.type && invalidText && (
											<p className="u-color-error">{invalidText}</p>
										)}
									</GridItem>
									<GridItem mdSize="auto">
										<Button type="submit" isWider isFullwidth isLoading={formState.isSubmitting}>
											{buttonLabel}
										</Button>
									</GridItem>
								</Grid>
							</form>
						</ContactForm>
					)}
				</MaxWidth>
			</RowMain>
		</ContactBox>
	);
};

type ContactFormResponse = {
	message: 'OK';
};

const fetchContactForm = async (data: ContactFormData): Promise<ContactFormResponse> => {
	const res = await fetch(`${process.env.API_URL}/form/pixii`, {
		method: 'POST',
		body: JSON.stringify(addHubspotParams(data)),
	});

	if (res.ok) {
		const data = await res.json();
		return data;
	} else {
		return handleFetchError(res);
	}
};
