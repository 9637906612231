import { FC, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDidMount } from '../../hooks/useDidMount';

export const PopupPortal: FC = ({ children }) => {
	const portalRef = useRef<HTMLDivElement>();
	useEffect(() => {
		const portalElement = document.createElement('div');
		document.body.appendChild(portalElement);
		portalRef.current = portalElement;

		return () => {
			document.body.removeChild(portalRef.current!);
			portalRef.current = undefined;
		};
	}, []);

	const didMount = useDidMount();

	return didMount && portalRef.current ? createPortal(children, portalRef.current) : null;
};
