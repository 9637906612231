import React from 'react';
import { CtaButton } from '../common/CtaButton';
import { MediaSwitch } from '../common/MediaSwitch';
import { PopupImage } from '../common/PopupImage';
import { HalfBox } from '../ui/HalfBox/HalfBox';
import { HalfBoxContent } from '../ui/HalfBox/HalfBoxContent';
import { HalfBoxSection } from '../ui/HalfBox/HalfBoxSection';
import { HalfBoxSide } from '../ui/HalfBox/HalfBoxSide';
import { HalfBoxSideHolder } from '../ui/HalfBox/HalfBoxSideHolder';
import { RowMain } from '../ui/layout/RowMain';
import { TeaserBox } from '../ui/Teaser/TeaserBox';
import { TeaserContent } from '../ui/Teaser/TeaserContent';
import { TeaserSection } from '../ui/Teaser/TeaserSection';
import { TeaserVideo } from '../ui/Teaser/TeaserVideo';
import { TeaserWrapper } from '../ui/Teaser/TeaserWrapper';
import { TextBlock } from '../ui/TextBlock/TextBlock';
import { VideoBox } from '../ui/Video/VideoBox';
import { VideoHolder } from '../ui/Video/VideoHolder';
import { Markdown } from './Markdown';
import { TPageBlock } from './pageBlockTypes';

type Props = Extract<TPageBlock, { __typename: 'ComponentPageSectionsSplitBoxImage' }>;

export const PageSectionsSplitBoxImage = ({
	content,
	media,
	mediaOnLeft,
	poster,
	youtubeId,
	splitBoxVariant,
	button,
	showFullscreen,
}: Props) => {
	return splitBoxVariant === 'video' ? (
		<TeaserSection>
			<TeaserWrapper>
				<RowMain>
					<TeaserBox>
						<TeaserContent>
							{content && <Markdown source={content} />}
							{button && (
								<>
									<p />
									<CtaButton button={button} variant="sm" />
								</>
							)}
						</TeaserContent>
						<TeaserVideo>
							<VideoBox>
								<VideoHolder>
									<MediaSwitch
										video={media}
										poster={poster}
										youtubeId={youtubeId}
										srcSet={[1170, 943, 598, 564]}
										sizes="(min-width: 1279px) 1170px, (min-width: 999px) 598px, (min-width: 749px) calc(100vw - 2 * 28px), (min-width: 599px) 564px, calc(100vw - 2 * 18px)"
										isCover={!showFullscreen}
									/>
								</VideoHolder>
							</VideoBox>
						</TeaserVideo>
					</TeaserBox>
				</RowMain>
			</TeaserWrapper>
		</TeaserSection>
	) : (
		<HalfBoxSection>
			<HalfBox isReverse={mediaOnLeft || false} variant={splitBoxVariant}>
				<HalfBoxContent>
					<TextBlock>{content && <Markdown source={content} />}</TextBlock>
					{button && (
						<>
							<p />
							<CtaButton button={button} variant="sm" />
						</>
					)}
				</HalfBoxContent>

				<HalfBoxSide>
					{media && media.mime && media.url && (
						<HalfBoxSideHolder>
							{showFullscreen ? (
								<PopupImage src={media.url}>
									<MediaSwitch
										video={media}
										poster={poster}
										youtubeId={youtubeId}
										srcSet={[1410, 1128, 705, 564]}
										sizes="(min-width: 1409px) 705px, (min-width: 749px) 50vw, (min-width: 599px) 564px, calc(100vw - 2 * 18px)"
										isCover={false}
									/>
								</PopupImage>
							) : (
								<MediaSwitch
									video={media}
									poster={poster}
									youtubeId={youtubeId}
									srcSet={[1410, 1128, 705, 564]}
									sizes="(min-width: 1409px) 705px, (min-width: 749px) 50vw, (min-width: 599px) 564px, calc(100vw - 2 * 18px)"
								/>
							)}
						</HalfBoxSideHolder>
					)}
				</HalfBoxSide>
			</HalfBox>
		</HalfBoxSection>
	);
};
