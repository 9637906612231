import { IconWrapper } from './IconWrapper';
import { FC } from 'react';

type Props = {
	width?: number;
	height?: number;
};

export const CrossIcon: FC<Props> = ({ width = 13, height = 12 }) => {
	return (
		<IconWrapper nativeWidth={13} nativeHeight={12} width={width} height={height}>
			<path d="M6,0C2.7,0,0,2.7,0,6s2.7,6,6,6s6-2.7,6-6S9.2,0,6,0z M6,11.1c-2.8,0-5.1-2.3-5.1-5.1S3.1,0.8,6,0.8 s5.1,2.3,5.1,5.1S8.8,11.1,6,11.1z" />
			<path d="M8.4,3.8c-0.2-0.2-0.5-0.2-0.6,0L6.1,5.5L4.5,3.8C4.3,3.7,4,3.7,3.8,3.8C3.7,4,3.7,4.3,3.8,4.5 l1.6,1.6L3.8,7.7c-0.2,0.2-0.2,0.5,0,0.6C3.9,8.4,4,8.5,4.1,8.5c0.1,0,0.2,0,0.3-0.1l1.6-1.6l1.6,1.6C7.8,8.4,7.9,8.5,8,8.5 c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.5,0-0.6L6.7,6.1l1.6-1.6C8.5,4.3,8.5,4,8.4,3.8z" />
		</IconWrapper>
	);
};
