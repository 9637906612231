import { FC } from 'react';
import classNames from 'classnames';

type Props = {
	variants?: 'hp';
};

export const Main: FC<Props> = ({ children, variants }) => {
	const classes = classNames('main page__main', variants && `main--${variants}`);
	return (
		<main role="main" className={classes}>
			{children}
		</main>
	);
};
