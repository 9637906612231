export type ApiError = {
	message: string;
	errorFields: string[];
};

export const handleFetchError = async (res: Response) => {
	let errorId = 'default';
	try {
		const data = (await res.json()) as ApiError;
		if (data?.message) errorId = data.message;
	} catch (e) {}
	throw new Error(errorId);
};
