import classNames from 'classnames';
import { FC } from 'react';

type Props = {
	align?: 'center';
};

export const ReasonHeading: FC<Props> = ({ children, align }) => {
	const classes = classNames('s-reason__heading', align && `u-text-${align}`);
	return <h2 className={classes}>{children}</h2>;
};
