import { FC, HTMLProps } from 'react';
import classNames from 'classnames';

type Props = {
	isHidden?: boolean;
} & HTMLProps<HTMLLabelElement>;

export const Label: FC<Props> = ({ children, isHidden, ...props }) => {
	const className = classNames('inp-label', isHidden ? 'u-vhide' : false);
	return (
		<label className={className} {...props}>
			{children}
		</label>
	);
};
